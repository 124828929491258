import { useState } from "react";
import { Dropdown } from "flowbite-react";
import { Input } from "@material-tailwind/react";
import AddIcon from "./icons/AddIcon";
import MagnifyingGlass from "../assets/icons/MagnifyingGlass";

const ReportDropdown = ({ resortId, templates, addReportMutation }) => {
  const [searchPhrase, setSearchPhrase] = useState<string>("");

  const onSearchChange = ({ target }: React.ChangeEvent<HTMLInputElement>) =>
    setSearchPhrase(target.value.toLocaleLowerCase());

  return (
    <Dropdown
      inline
      label={
        <div
          className=" self-center p-1
rounded-lg hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700
  flex
  "
        >
          <AddIcon />
        </div>
      }
      arrowIcon={false}
    >
      <Dropdown.Header>
        <Input
          icon={<MagnifyingGlass />}
          variant="outlined"
          type="search"
          label="Wyszukaj"
          onKeyDown={(e) => e.stopPropagation()}
          onChange={onSearchChange}
        />
      </Dropdown.Header>
      {templates
        .filter(({ name }) => name.toLocaleLowerCase().includes(searchPhrase))
        .map((template) => (
          <Dropdown.Item
            onClick={() =>
              addReportMutation({
                resortId,
                templateId: template.id,
              })
            }
          >
            {template.name}
          </Dropdown.Item>
        ))}
    </Dropdown>
  );
};

export default ReportDropdown;
