import axios from "axios";
import { ApiURI } from "../types/configs";
import {
  QuestionDescriptionDTO,
  QuestionDescriptionWithAnswersDTO,
  QuestionDTO,
  QuestionWithAnswers,
} from "../types/Rows";
import { headers } from "./headers";
import {
  ProblemDictionaryWithReportsList,
  ProblemsDictionary,
} from "../types/Metadata";

export const QuestionsInstance = axios.create({
  baseURL: ApiURI + "Questions/",
});

export const getAllQuestions = async () => {
  let result = await QuestionsInstance.get<QuestionDescriptionDTO[]>(
    "descriptions",
    headers()
  );
  return result.data;
};

export const getQuestionDescription = async (id: string) => {
  let result = await QuestionsInstance.get<QuestionDescriptionWithAnswersDTO>(
    "descriptions/" + id,
    headers()
  );
  return result.data;
};

export const addQuestionDescription = async (value) => {
  let result = await QuestionsInstance.post(
    "" ,
    value,
    headers()
  );
  return result.data;
};

export const getTemplateProblems = async () => {
  return (
    await QuestionsInstance.get<ProblemsDictionary>(
      "problemsDictionary",
      headers()
    )
  ).data;
};

export const getTemplateProblem = async (id: string) => {
  return (
    await QuestionsInstance.get<ProblemDictionaryWithReportsList>(
      "/problemsDictionary/" + id,
      headers()
    )
  ).data;
};

export const deleteTemplateProblem = async (id: string) => {
  return (
    await QuestionsInstance.delete<ProblemDictionaryWithReportsList>(
      "/problemsDictionary/" + id,
      headers()
    )
  ).data;
};
