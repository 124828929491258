
import { Outlet } from "react-router-dom";
import { useNavigationBadgesStore } from "../..";
import { Navbar } from "./Navbar";

export const ProtectedRoute = ({ children }: any) => {

  const navigationBadges = useNavigationBadgesStore(
    (state: any) => state.navigationBadges
  );

  // If authenticated, render the child routes
  return (
    <>
      <Navbar items={navigationBadges} />
      <Outlet />
    </>
  );
};
