import React, { useState, useEffect } from "react";
import { fetchLogs } from "../../api/userApi";
import { Log } from "../../models/Log";
import { Button, IconButton, Card, CardBody, CardFooter, Typography, Dialog, DialogHeader, DialogBody, DialogFooter } from "@material-tailwind/react";
import { ArrowRightIcon, ArrowLeftIcon } from "@heroicons/react/24/outline";

const AuditComponent: React.FC = () => {
  const [items, setItems] = useState<Log[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedItem, setSelectedItem] = useState<Log | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const itemsPerPage = 10;
  const totalPages = 10; // Allow for 10 pages

  useEffect(() => {
    const fetchItems = async () => {
      const data = await fetchLogs(100, currentPage, itemsPerPage);
      if (Array.isArray(data)) {
        setItems(data);
      }
    };

    fetchItems();
  }, [currentPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const next = () => {
    if (currentPage === totalPages) return;
    setCurrentPage(currentPage + 1);
  };

  const prev = () => {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  };

  const getItemProps = (index: number) => ({
    variant: currentPage === index ? "filled" : "text" as "filled" | "text",
    color: "gray" as any,
    onClick: () => handlePageChange(index),
    className: "rounded-full",
  });

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = items.slice(indexOfFirstItem, indexOfLastItem);

  const handleViewDetails = (item: Log) => {
    setSelectedItem(item);
    setIsDialogOpen(true);
  };

  const closeDialog = () => {
    setIsDialogOpen(false);
    setSelectedItem(null);
  };

  return (
    <div>
      <Typography variant="h4" color="blue-gray" className="ml-4">
        Audit Items
      </Typography>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
        {currentItems.map((item) => (
          <Card key={item.id} className="shadow-lg">
            <CardBody>
              <Typography variant="h6" color="blue-gray">
                Message: {item.message}
              </Typography>
              <Typography variant="small" color="gray">
                Created: {new Date(item.created).toLocaleString()}
              </Typography>
              <Typography variant="small" color="gray">
                Created By: {item.createdBy}
              </Typography>
              <Typography variant="small" color="gray">
                URL: {item.url}
              </Typography>
            </CardBody>
            <CardFooter className="pt-0">
              <Button variant="text" color="blue" size="sm" onClick={() => handleViewDetails(item)}>
                View Details
              </Button>
            </CardFooter>
          </Card>
        ))}
      </div>
      <div className="flex items-center gap-4 mt-4">
        <Button
          variant="text"
          className="flex items-center gap-2 rounded-full"
          onClick={prev}
          disabled={currentPage === 1}
        >
          <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" /> Previous
        </Button>
        <div className="flex items-center gap-2">
          {Array.from({ length: totalPages }, (_, index) => (
            <IconButton key={index} {...getItemProps(index + 1)}>
              {index + 1}
            </IconButton>
          ))}
        </div>
        <Button
          variant="text"
          className="flex items-center gap-2 rounded-full"
          onClick={next}
          disabled={currentPage === totalPages}
        >
          Next
          <ArrowRightIcon strokeWidth={2} className="h-4 w-4" />
        </Button>
      </div>
      <Dialog open={isDialogOpen} handler={closeDialog}>
        <DialogHeader>Log Details</DialogHeader>
        <DialogBody divider style={{ maxHeight: '80vh', overflowY: 'auto' }}>
          {selectedItem && (
            <>
              <Typography variant="h6" color="blue-gray">
                Request Body:
              </Typography>
              <Typography variant="small" color="gray">
                {selectedItem.requestBody}
              </Typography>
              <Typography variant="h6" color="blue-gray" className="mt-4">
                Stack Trace:
              </Typography>
              <Typography variant="small" color="gray">
                {selectedItem.stackTrace}
              </Typography>
            </>
          )}
        </DialogBody>
        <DialogFooter>
          <Button variant="text" color="red" onClick={closeDialog}>
            Close
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default AuditComponent;
