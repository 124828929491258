import { useEffect } from "react";
import {
  Breadcrumbs,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import { HiHome } from "react-icons/hi";
import { useMsal } from "@azure/msal-react";
import { useUserContext } from "./authProvider";
import { Button } from "@material-tailwind/react";
import { NavigationBadge } from "../..";
import { ReportsInstance, TemplatesInstance } from "../../api/reportsApi";
import { UsersInstance } from "../../api/userApi";
import { StudyInstance } from "../../api/studyApi";

const Navbar = ({ items }: { items: NavigationBadge[] }) => {
  const { userName } = useUserContext();
  const { instance } = useMsal();

  const clients = [
    UsersInstance,
    TemplatesInstance,
    ReportsInstance,
    StudyInstance,
  ];

  useEffect(() => {
    let token = localStorage.getItem("webApiAccessToken");
    if (token) {
      clients.forEach((x) => {
        x.defaults.headers.common["Authorization"] = "Bearer " + token;
      });
    }
  }, [clients, instance]);

  return (
    <div
      style={{ zIndex: 100 }}
      className="bg-gray-50 px-5 py-3 dark:bg-gray-900 flex flex-col md:flex-row justify-between items-center z-10"
    >
      <Breadcrumbs className="flex items-center space-x-2 mb-3 md:mb-0">
        <a href="/" className="opacity-60 hover:opacity-100">
          <HiHome />
        </a>
        {items.map((item, index) => {
          return (
            <>
              {!item.links && (
                <a href={item.url} className="text-black hover:text-gray-700">
                  {item.text}
                </a>
              )}
              {item.links && (
                <Menu>
                  <MenuHandler>
                    <Button variant="text">{item.text}</Button>
                  </MenuHandler>
                  <MenuList>
                    {item.links?.map((dropdownLink) => (
                      <MenuItem>
                        <a
                          href={dropdownLink.value}
                          className="hover:text-gray-700"
                        >
                          {dropdownLink.name}
                        </a>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              )}
            </>
          );
        })}
      </Breadcrumbs>
      {/* <span className="text-black">{userName}</span> */}
      <div className="flex flex-col md:flex-row gap-5 items-center">
        <a href="/" className="text-black hover:text-gray-700">
          {userName}
        </a>
      </div>
      <div className="flex flex-col md:flex-row gap-5 items-center">
        <a href="/" className="text-black hover:text-gray-700">
          Badania
        </a>
        <a href="/reports" className="text-black hover:text-gray-700">
          Do weryfikacji
        </a>
        <a href="/Issues" className="text-black hover:text-gray-700">
          Lista znalezisk
        </a>
        <a href="/Questions" className="text-black hover:text-gray-700">
          Pytania
        </a>
        <a
          href="#"
          onClick={() => {
            instance.logout();
          }}
          className="text-black hover:text-gray-700"
        >
          Wyloguj się
        </a>
      </div>
    </div>
  );
};

export { Navbar };
