import axios from "axios";
import {
  EditableReportQuestion,
  FormTemplate,
  TemplateEditableSection,
} from "../types/Rows";
import { ApiURI } from "../types/configs";
import { headers } from "./headers";
import { StudyInstance } from "./studyApi";

export const TemplatesInstance = axios.create({
  baseURL: ApiURI + "Templates/",
});

export const fetchTemplate = async (id: string) => {
  return (await TemplatesInstance.get<FormTemplate>(id, headers())).data;
};

export const deleteTemplate = async (id: string) => {
  console.log("deleting template id", id);
  return await TemplatesInstance.delete<any>(id, headers());
};

export const updateTemplateName = async (id: string, name: string) => {
  return await TemplatesInstance.patch<any>(id + "/name/" + name, headers());
};

export const toggleTemplate = async (id: string) => {
  return await TemplatesInstance.put<any>(id + "/locked", {}, headers());
};

export const deleteSection = async (templateId: string, id: string) => {
  return await TemplatesInstance.delete<any>(
    templateId + "/sections/" + id,
    headers()
  );
};

export const fetchAllQuestions = async () => {
  return (
    await TemplatesInstance.get<EditableReportQuestion[]>(
      "questions/",
      headers()
    )
  ).data;
};

export const fetchTemplateQuestions = async (id: string) => {
  return (
    await TemplatesInstance.get<TemplateEditableSection[]>(
      id + "/questions/",
      headers()
    )
  ).data;
};

export const assignQuestionToSection = async (
  templateId: string,
  sectionId: string,
  descriptionId: string
) => {
  return await TemplatesInstance.post<EditableReportQuestion[]>(
    templateId + "/sections/" + sectionId + "/questions/" + descriptionId,
    {},
    headers()
  );
};

export const assignQuestionsFromTemplate = async (
  templateId: string,
  sourceId: string
) => {
  return await TemplatesInstance.put<any>(
    templateId + "/questions/templates/" + sourceId,
    {},
    headers()
  );
};

export const updateSections = async (
  templateId: string,
  sections: TemplateEditableSection[]
) => {
  return await TemplatesInstance.put<any>(
    templateId + "/sections/",
    sections,
    headers()
  );
};

export const getAllAvailableTemplates = async (studyId: string) => {
  return await StudyInstance.get<FormTemplate[]>(
    studyId + "/templates",
    headers()
  );
};

export const createFromExistingTemplate = async (
  studyId: string,
  originTemplateId: string
) => {
  return await TemplatesInstance.post<any>(
    "",
    {
      studyId: studyId,
      originTemplateId: originTemplateId,
    },
    headers()
  );
};

export const addSection = async (templateId: string, name: string) => {
  return await TemplatesInstance.post<any>(
    templateId + "/sections/" + name,
    {},
    headers()
  );
};


export const getTemplate = async (id: string) => {
  return (await TemplatesInstance.get<FormTemplate>(id, headers())).data;
};

export const updateQuestions = async (
  templateId: string,
  questions: EditableReportQuestion[]
) => {
  return await TemplatesInstance.put<any>(
    templateId + "/questions",
    questions,
    headers()
  );
};

export const deleteQuestion = async (
  templateId: string,
  questionsId: string
) => {
  return await TemplatesInstance.delete<any>(
    templateId + "/questions/" + questionsId,
    headers()
  );
};

export const updateFootnote = async (templateId: string, value: string) => {
  return await TemplatesInstance.patch<any>(
    templateId + "/footnote/",
    { value: value },
    headers()
  );
};

export const updateFootnoteFul = async (templateId: string, value: string) => {
  return await TemplatesInstance.patch<any>(
    templateId + "/footnoteful/",
    { value: value },
    headers()
  );
};
