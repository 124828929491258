"use client";
import { Button } from "@material-tailwind/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import {
  addReport,
  addResort,
  deleteResort,
  deleteStudy,
  editResort,
  fetchStudy,
  updateStudyDescription,
} from "../../api/studyApi";
import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import { NavigationBadge, useNavigationBadgesStore } from "../..";
import { getTemplates } from "../../api/reportsApi";
import Tabs from "../../components/tabs";
import { Report, ReportState } from "../../types/Rows";
import AddItemsModal from "../template/addItemsModal";
import AddItemModal from "../template/addItemModal";
import IncludedEmplyeesSection from "./IncludedEmployeesSection";
import Templates from "./Templates";
import Reports from "./Reports";

export const StudyPage = () => {
  let { studyId } = useParams();

  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const setItems = useNavigationBadgesStore((state: any) => state.setItems);

  const { data, error, refetch } = useQuery(
    ["Studies", studyId!],
    () => fetchStudy(studyId!),
    {}
  );

  const { data: templates } = useQuery(
    ["Templates"],
    async () => getTemplates(studyId),
    {
      enabled: studyId != null,
      initialData: [],
    }
  );

  useEffect(() => {
    if (data) {
      setItems([
        { text: "Badania", url: "/" } as NavigationBadge,
        { text: data.name, url: "" } as NavigationBadge,
      ]);
    }
  }, [data, setItems]);

  const { mutate: addResortMutation } = useMutation(
    async ({ name, address }: { name: string; address: string }) => {
      return addResort(studyId!, name, address);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["Studies", studyId]);
      },
    }
  );

  const { mutate: editResortMutation } = useMutation(
    async ({
      resortId,
      name,
      address,
    }: {
      resortId: string;
      name: string;
      address: string;
    }) => {
      return editResort(studyId!, resortId, name, address);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["Studies", studyId]);
      },
    }
  );

  const { mutate: updateDescription } = useMutation(
    async (description: string) => {
      return updateStudyDescription(studyId!, description);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["Studies", studyId]);
      },
    }
  );

  const { mutate: resortDelete } = useMutation(
    async (resortId: string) => {
      return deleteResort(studyId!, resortId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["Studies", studyId]);
      },
    }
  );

  const { mutate: addReportMutation } = useMutation(
    async ({
      resortId,
      templateId,
    }: {
      resortId: string;
      templateId: string;
    }) => {
      await addReport(studyId!, resortId, templateId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["Studies", studyId!]);
      },
    }
  );

  if (data == null) {
    return <></>;
  }

  return (
    <div className="App">
      <Tabs
        categories={[
          { id: "1", component: <Reports data={data} studyId={studyId} templates={templates} />, title: "Raporty" },
          {
            id: "2",
            component: <Templates templates={templates} studyId={studyId!} />,
            title: "Szablony",
          },
          {
            id: "3",
            component: <IncludedEmplyeesSection studyId={studyId!} />,
            title: "CRO",
          },
        ]}
      ></Tabs>
    </div>
  );
};
