import { useCallback, useEffect, useState } from "react";
import {
  EditableReportQuestion,
  TemplateEditableSection,
} from "../../../types/Rows";
import { useTemplateContext } from "../templateContext";
import { UseQuestionsTree } from "../../../hooks/useQuestionsTree";
import { DndProvider } from "react-dnd";
import update from "immutability-helper";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DraggableItem } from "../DragAndDrop/DraggableItem";
import { Row } from "./questionsListEdit";
import { Button, Input } from "@material-tailwind/react";
import { ItemTypes } from "../DragAndDrop/ItemTypes";
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";
import { deleteSection } from "../../../api/reportsApi";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const QuestionItem = ({
  templateId,
  sectionId,
  isLocked,
  item,
  index,
}: {
  templateId: string;
  sectionId: string;
  isLocked: boolean;
  item: EditableReportQuestion;
  index: number;
}) => {
  return (
    <>
      <Row
        templateId={templateId}
        index={index}
        key={item.weight + item.questionId}
        input={item}
        sectionId={sectionId}
        isLocked={isLocked}
        depth={0}
      ></Row>
      {item.children
        ?.sort((a, b) => (a.weight < b.weight ? -1 : 1))
        .map((child) => (
          <div className="pl-10">
            <Row
              templateId={templateId}
              index={index}
              key={child.weight + child.questionId}
              input={child}
              sectionId={sectionId}
              isLocked={isLocked}
              depth={1}
            ></Row>
            {child.children
              ?.sort((a, b) => (a.weight < b.weight ? -1 : 1))
              .map((childrenChild) => (
                <div className="pl-10">
                  <Row
                    templateId={templateId}
                    index={index}
                    key={childrenChild.weight + childrenChild.questionId}
                    input={childrenChild}
                    sectionId={sectionId}
                    isLocked={isLocked}
                    depth={2}
                  ></Row>
                </div>
              ))}
          </div>
        ))}
    </>
  );
};

export const SectionEdit = ({
  section,
  items,
  templateId,
  isLocked,
  index,
}: {
  section: TemplateEditableSection;
  items: EditableReportQuestion[];
  templateId: string;
  isLocked: boolean;
  index: number;
}) => {
  const { updateSection } = useTemplateContext();
  const [name, setName] = useState<string>(section.name);
  const [weight, setWeight] = useState<number>(section.weight);

  const [currentItems, setCurrentItems] = useState(
    items
      .filter((x) => x.parentId == null)
      ?.sort((a, b) => (a.weight < b.weight ? -1 : 1))
  );
  const queryClient = useQueryClient();

  const { mutate: deleteSec } = useMutation(
    async () => await deleteSection(templateId, section.id),

    {
      onSuccess: () => {
        queryClient.invalidateQueries(["Templates", templateId]);
      },
    }
  );

  const { updateQuestionWeight } = useTemplateContext();

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setCurrentItems((prevCards: EditableReportQuestion[]) => {
      updateQuestionWeight(prevCards[dragIndex], hoverIndex);
      // updateQuestionWeight(prevCards[hoverIndex], dragIndex);

      return update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex] as EditableReportQuestion],
        ],
      });
    });
  }, []);

  UseQuestionsTree({ items: items });

  useEffect(() => {
    updateSection({
      ...section,
      weight: weight,
      name: name,
    } as TemplateEditableSection);
  }, [name, weight]);

  const renderCard = useCallback(
    (card: EditableReportQuestion, index: number) => {
      return (
        <DraggableItem
          itemType={ItemTypes.CARD}
          key={card.id}
          index={index}
          id={card.id}
          text={card.description}
          moveCard={moveCard}
        >
          <QuestionItem
            item={card}
            sectionId={section.id}
            isLocked={isLocked}
            templateId={templateId}
            index={index}
          />
        </DraggableItem>
      );
    },
    []
  );

  return (
    <div className="rounded-lg border border-black text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 p-2">
      <div className="flex flex-col sm:flex-row justify-between w-full font-extrabold rounded-lg break-words p-2">
        <div className="flex flex-col sm:flex-row sm:items-center">
          <Input
            variant="static"
            size="md"
            label="kolejność"
            type="numeric"
            value={weight}
            onChange={(val) =>
              setWeight(Number.parseInt(val.target.value) || 0)
            }
            className="mb-2 sm:mb-0 sm:mr-2"
          />
          <Input
            label="Nazwa sekcji"
            variant="static"
            style={{ width: "350px" }}
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            className="mb-2 sm:mb-0 sm:mr-2"
          />
        </div>
        <div className="flex flex-col sm:flex-row sm:items-center">
          <Button
            variant="outlined"
            onClick={async () => {
              let result = window.confirm("Czy na pewno chcesz usunąć?");
              if (result) {
                await deleteSec();
              }
            }}
          >
            Usuń
          </Button>
        </div>
      </div>
      <div className="p-1">
        <DndProvider backend={HTML5Backend}>
          {currentItems.map((card, i) => renderCard(card, i))}
        </DndProvider>
      </div>
    </div>
  );
};
