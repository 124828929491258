import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { NavigationBadge, useNavigationBadgesStore } from "../..";
import { addStudy, fetchStudies } from "../../api/studyApi";
import ItemsList from "../../components/lists/ItemsList";
import { AddIcon } from "../studies/AddIcon";
import AddItemModal from "../template/addItemModal";


export const Root = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const setItems = useNavigationBadgesStore((state: any) => state.setItems);

  const { data, error, refetch, isFetching, isLoading } = useQuery(
    ["Studies"],
    fetchStudies,
    {
      initialData: [],
    }
  );

  useEffect(() => {
    if (data) {
      setItems([{ text: "Badania", url: "/" } as NavigationBadge]);
    }
  }, [data, setItems]);

  const { mutate: add } = useMutation(
    async (name: string) => {
      return addStudy(name);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["Studies"]);
      },
    }
  );

  // if (isFetching) {
  //   return (
  //     <div role="status" className="flex items-center justify-center h-screen">
  //       <LoadingSpinner />
  //     </div>
  //   );
  // }

  return (
    <div className="App">
      <div className="mx-auto max-w-7xl sm:px-6 mt-3 lg:px-8">
        <div className="pb-8 sm:px-0">
          <div className="rounded-lg h-96">
            {" "}
            <ItemsList
              isLoading={isFetching}
              data={data}
              urlPrefix="study/"
              isSortVisible
              isSearchVisible
            >
              <header>
                <div className="max-w-7xl">
                  <h1 className="text-3xl font-bold leading-tight text-gray-900 flex gap-1">
                    {t("researchPlural")}
                    <AddItemModal
                      saveItem={(name) => add(name)}
                      text={"Dodaj badanie"}
                    >
                      <AddIcon />
                    </AddItemModal>
                  </h1>
                </div>
              </header>{" "}
            </ItemsList>
          </div>
        </div>
      </div>
    </div>
  );
  //   <div className="App">
  //     <header className="App-header">{/* <Report /> */}</header>
  //     <div className="flex">
  //       <div className="flex flex-col">
  //         <div className="p-5">
  //           <div className="flex align-middle">
  //             <span className="text-4xl">Reports</span>
  //           </div>
  //           <div>
  //             {data.map((x) => {
  //               return (
  //                 <a key={"reports" + x.id} href={`reports/${x.id}`}>
  //                   <p className="text-blue-600 underline grid grid-cols-2">
  //                     <div>{x.name}</div>
  //                     <div className="pl-2">
  //                       {" "}
  //                       {new Date(x.created).toLocaleDateString()}
  //                     </div>
  //                   </p>
  //                 </a>
  //               );
  //             })}
  //           </div>
  //         </div>

  //         <div className="p-5">
  //           <div className="flex align-middle">
  //             <span className="text-4xl">Templates </span>
  //             <AddButton
  //               text="Add Template"
  //               saveItem={(name) => saveTemplate.mutate(name)}
  //             />
  //           </div>
  //           <div>
  //             {templates?.map((x) => {
  //               return (
  //                 <p>
  //                   <a
  //                     className="text-blue-600 underline grid grid-cols-2"
  //                     key={"template" + x.id}
  //                     href={`template/${x.id}`}
  //                   >
  //                     <div>{x.name}</div>
  //                     <div> {new Date(x.created).toLocaleDateString()}</div>
  //                   </a>
  //                 </p>
  //               );
  //             })}
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );
};
