import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { assignAccessToEmployee } from "../../api/userApi";
import AddItemModal from "../template/addItemModal";
import { getAccess } from "../../api/studyApi";

const IncludedEmplyeesSection = ({ studyId }: { studyId: string }) => {
  const queryClient = useQueryClient();

  const { mutate: addAccessToTheUser } = useMutation(
    ["Employees", studyId!],
    (name: string) => assignAccessToEmployee(name, studyId!),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["Employees", studyId!]);
      },
    }
  );

  const { data, refetch } = useQuery(
    ["Employees", studyId!],
    async () => await getAccess(studyId!),
    {}
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <div className="p-3">
      <div className="flex justify-between">
        <h1 className="text-3xl font-extrabold dark:text-white">
          <small className="ml-2 font-semibold text-gray-500 dark:text-gray-400">
            Uwzględnieni CRO
          </small>
        </h1>{" "}
        <AddItemModal
          text="Wprowadź email"
          saveItem={(name) => {
            addAccessToTheUser(name);
          }}
        >
          <div className="p-3 ">Dodaj uzytkownika</div>
        </AddItemModal>
      </div>
      <div className="p-3">
        {data?.map((x: any) => (
          <div className="ml-2">
            <div>- {x}</div>
          </div>
        ))}
      </div>{" "}
    </div>
  );
};

export default IncludedEmplyeesSection;
