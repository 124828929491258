import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addNewProblem,
  addProblemFromExisting,
  updateTemplateProblemAnswers,
} from "../../api/reportsApi";
import { ProblemsDictionary } from "../../types/Metadata";
import { ProblemAnswer } from "../../types/Rows";
import { useProblemAnswers } from "../..";
import { getTemplateProblems } from "../../api/questionsApi";
import { successToast } from "../../helpers/successToast";
import AddItemWithExistingPicker, {
  InputType,
} from "../../pages/template/AddItemWithExistingPicker";
import { PlusIcon } from "../../types/PlusIcon";
import { ProblemRow } from "./ProblemRow";
import { sortIssues } from "../helpers/helpers";

import { ProblemsHeader } from "./ProblemsHeader";
import { ProblemsList } from "./problemsList";

export const ProblemsSection = ({
  items,
  reportId,
}: {
  items: ProblemAnswer[];
  templateId: string;
  reportId: string;
}) => {
  const { problemAnswers } = useProblemAnswers();
  const queryClient = useQueryClient();

  const { mutateAsync: saveProblemsAnswers, isSuccess: isSaveProblemSuccess } =
    useMutation(
      async (problemAnswers: ProblemAnswer[]) => {
        if (problemAnswers.length > 0)
          return updateTemplateProblemAnswers(reportId!, problemAnswers);
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries([reportId, "Problems"]);
        },
      }
    );

  const { data } = useQuery(
    ["ReportProblemsDictionary"],
    async () => getTemplateProblems(),
    { initialData: { categories: [], descriptions: [] } as ProblemsDictionary }
  );

  const { mutate: addProblemTypeToDictionary } = useMutation(
    async ({ reportId, value }: { reportId: string; value: string }) =>
      addNewProblem(reportId, value),
    {
      onSuccess: (response) => {
        queryClient.resetQueries([reportId, "Problems"]);
        successToast();
      },
    }
  );

  const { mutate: addProblemQuestions } = useMutation(
    async ({
      reportId,
      categoryId,
    }: {
      reportId: string;
      categoryId: string;
    }) => addProblemFromExisting(reportId, categoryId),
    {
      onSuccess: (response) => {
        queryClient.resetQueries([reportId, "Problems"]);
        successToast();
      },
    }
  );

  return (
    <div>
      <div className="text-xl text-center mt-1">Lista znalezisk</div>
      <div className="sm:rounded-lg border border-neutral-900 m-3 overflow-x-auto">
        <ProblemsHeader
          saveProblemsAnswers={saveProblemsAnswers}
          problemAnswers={problemAnswers}
          addProblemQuestions={addProblemQuestions}
          addProblemTypeToDictionary={addProblemTypeToDictionary}
          reportId={reportId}
        />
        <ProblemsList items={items} reportId={reportId} />
      </div>
    </div>
  );
};
