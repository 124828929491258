import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  getAllAvailableTemplates,
  createFromExistingTemplate,
} from "../../api/reportsApi";
import ItemsList from "../../components/lists/ItemsList";
import AddItemWithExistingPicker, {
  InputType,
} from "../template/AddItemWithExistingPicker";
import AddIcon from "../../components/icons/AddIcon";
import { FormTemplate } from "../../types/Rows";
import { addTemplate } from "../../api/studyApi";

const Templates = ({
  templates,
  studyId,
}: {
  templates: FormTemplate[];
  studyId: string;
}) => {
  const queryClient = useQueryClient();

  const { mutate: addTemplateMutation } = useMutation(
    async (name: string) => {
      await addTemplate(studyId!, name);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["Templates"]);
        queryClient.invalidateQueries(["Studies"]);
      },
    }
  );

  const { mutate: createFromExisting } = useMutation(
    async (id: string) => {
      await createFromExistingTemplate(studyId!, id);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["Templates"]);
        queryClient.invalidateQueries(["Studies"]);
      },
    }
  );

  return (
    <div className="p-3">
      <ItemsList
        isLoading={false}
        data={templates.filter((x) => x.studyId == studyId)}
        urlPrefix="/templates/"
        isSearchVisible
      >
        <div className="flex flex-1 items-end gap-3">
          <h1 className="text-5xl font-extrabold dark:text-white">
            <small className="font-semibold">Szablony </small>
          </h1>{" "}
          <AddItemWithExistingPicker
            text="Dodaj szablon"
            saveNew={(value) => {
              addTemplateMutation(value);
            }}
            saveExisting={(id: string) => createFromExisting(id)}
            inputType={InputType.SINGLE}
            // items={async () =>
            //   (await allAvailableTemplates
            //     .filter(
            //       (x) =>
            //         !templates.find((y) => y.id == x.id) && x.studyId == studyId
            //     )
            //     .map((x) => ({
            //       id: x.id,
            //       name: x.name,
            //     }))) ?? []
            // }
          >
            <AddIcon />
          </AddItemWithExistingPicker>
        </div>
      </ItemsList>
    </div>
  );
};

export default Templates;
