import {
  QueryKey,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { NavigationBadge, useNavigationBadgesStore } from "../..";
import TemplatesToolbar from "../../components/toolbar/TemplatesToolbar";
import { lockToast } from "../../helpers/successToast";
import { QuestionDescriptionDTO } from "../../types/Rows";
import { EditableSectionsList } from "./Questions/sectionsListEdit";
import { TemplateContextProvider } from "./templateContext";
import { Textarea } from "@material-tailwind/react";
import {
  assignQuestionToSection,
  fetchTemplate,
  fetchTemplateQuestions,
} from "../../api/templatesApi";
import { fetchAllDescriptions } from "../../api/reportsApi";

function TemplatePage() {
  let { templateId } = useParams();
  const queryClient = useQueryClient();
  const [isLocked, setIsLocked] = useState<boolean>(true);

  const { data, isFetching, isLoading, error } = useQuery(
    ["Templates", templateId] as unknown as QueryKey,
    async () => {
      let res = await fetchTemplate(templateId!);
      return res;
    },
    { enabled: templateId != undefined, refetchOnMount: false }
  );

  const [footer, setFooter] = useState<string>(data?.footer);
  const [footerFul, setFooterFul] = useState<string>(data?.footerFul);

  const { data: templateQuestions } = useQuery(
    ["Templates", templateId, "Questions"],
    async () => {
      let res = await fetchTemplateQuestions(templateId!);
      return res;
    },
    { enabled: templateId != undefined }
  );

  const setItems = useNavigationBadgesStore((state: any) => state.setItems);

  useEffect(() => {
    if (data) {
      setItems([
        { text: "Badania", url: "/" } as NavigationBadge,
        {
          text: data.studyName,
          url: "/study/" + data.studyId,
        } as NavigationBadge,

        {
          text: "Szablon - " + data.name,
          url: "/templates/" + data.id,
        } as NavigationBadge,
      ]);
      setFooter(data.footer);
      setFooterFul(data.footerFul);
      setIsLocked(data.isLocked);
    }
  }, [data, setItems]);

  const { data: allQuestions } = useQuery(
    ["Descriptions"],
    async () => await fetchAllDescriptions()
  );

  const [filteredAvailableQuestions, setFilteredAvailableQuestions] = useState<
    QuestionDescriptionDTO[]
  >([]);

  useEffect(() => {
    if (allQuestions && data) {
      setFilteredAvailableQuestions(
        allQuestions.filter(
          (x) =>
            !templateQuestions
              ?.flatMap((sections) => sections.questions ?? [])
              .flatMap((q) => q.id ?? [])
              .includes(x.id)
        )
      );
    }
  }, [allQuestions, data, templateQuestions]);

  const { mutate: saveQuestion } = useMutation(
    ({
      templateId,
      sectionId,
      descriptionId,
    }: {
      templateId: string;
      sectionId: string;
      descriptionId: string;
    }) => assignQuestionToSection(templateId, sectionId, descriptionId),
    {
      onSuccess: () => {
        queryClient.resetQueries(["Templates", templateId, "Questions"]);
      },
    }
  );

  if (isFetching || !data) {
    return (
      <>
        <h1 className="text-2xl"> Loading </h1>
      </>
    );
  }

  return (
    <div>
      <TemplatesToolbar
        name={data.name}
        templateId={templateId}
        isLocked={isLocked}
        author={data.createdBy}
        footer={footer}
        footerFul={footerFul}
      />
      <div className="p-5">
        <div style={{ width: "100%" }} className="flex justify-between"></div>

        <EditableSectionsList
          sections={templateQuestions ?? []}
          templateId={templateId!}
          isLocked={isLocked}
          existingItems={filteredAvailableQuestions}
          saveExisting={(sectionId, descriptionId) => {
            if (isLocked) {
              lockToast();
              return;
            }
            return saveQuestion({
              templateId: templateId!,
              sectionId: sectionId,
              descriptionId: descriptionId,
            });
          }}
        />
      </div>

      <div className="p-5">
        <Textarea
          label="Stopka"
          className="border"
          style={{ height: "100%", width: "100%" }}
          value={footer}
          onChange={(e) => {
            setFooter(e.target.value);
          }}
        />

        <Textarea
          label="Stopka FUL"
          className="border"
          style={{ height: "100%", width: "100%" }}
          value={footerFul}
          onChange={(e) => {
            setFooterFul(e.target.value);
          }}
        />
      </div>
    </div>
  );
}

export const TemplatePageWrapper = () => {
  return (
    <TemplateContextProvider>
      <TemplatePage />
    </TemplateContextProvider>
  );
};
