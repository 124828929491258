import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate, useParams } from "react-router-dom";
import { deleteTemplateProblem, getTemplateProblem } from "../../api/questionsApi";
import { NavigationBadge, useNavigationBadgesStore } from "../..";
import { useEffect, useState } from "react";
import ItemsList from "../../components/lists/ItemsList";
import { BaseDto } from "../../types/Rows";
import { Button } from "@material-tailwind/react";
import { successToast } from "../../helpers/successToast";

export const IssuePage = () => {
  let { categoryId } = useParams();
  const setItems = useNavigationBadgesStore((state: any) => state.setItems);
  const [templates, setTemplates] = useState<BaseDto[]>([]);
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { data, isFetching, error, refetch } = useQuery(
    ["Issues", categoryId!],
    () => getTemplateProblem(categoryId!),
    {}
  );

  const { mutate: deleteProblemCategory } = useMutation(
    async () => deleteTemplateProblem(categoryId),
    {
      onSuccess: (response) => {
        queryClient.resetQueries(["Issues", categoryId!]);
        successToast();
        navigate("/Issues", {replace:true});
      },
    }
  );
  
  useEffect(() => {

    if (data) {
      const reportsMap = new Map<string, BaseDto>();

      data.reports.forEach(
        (x) =>{
          if(!reportsMap.has(x.templateId))
          reportsMap.set(x.templateId, ({
            name: x.templateName,
            id: x.templateId,
            created: x.created,
            createdBy: x.createdBy,
            description: x.description,
          } as BaseDto))}
      )
      setTemplates([...reportsMap.values()]);
  
      setItems([
        { text: "Issues", url: "/Issues" } as NavigationBadge,
        {
          text: data.description.substring(0, 40) + "...",
          url: "/Issues/" + data.categoryId,
        } as NavigationBadge,
      ]);
    }
  }, [data, setItems]);

  if (isFetching || !data) return <>Loading</>;

  return (
    <div className="App">
      <div className="p-5 flex flex-col gap-5">
        <div>
          <div className="flex content-between justify-between">
            <div>
              <h1 className="text-3xl font-extrabold dark:text-white">
                {data.description}
              </h1>
            </div>

            <Button onClick={async ()=>{
              let res = window.confirm("Usunięcie spowoduje również usunięcie powiązanych odpowiedzi w raportach. Kontynuować?");
              if(res){
                await deleteProblemCategory();
              }
            }} variant="outlined">Usuń</Button>
          </div>
        </div>
        <ItemsList
          isLoading={isFetching}
          data={templates}
          urlPrefix="/templates/"
          isSortVisible
        >
          <header>
            <div className="max-w-7xl">
              <h1 className="text-3xl font-bold leading-tight text-gray-900">
                Szablony
              </h1>
            </div>
          </header>{" "}
        </ItemsList>
        <ItemsList
          isLoading={isFetching}
          data={data.answers.map(
            (x) =>
              ({
                name: x.reportName + + " " + x.resortName+ " - " +  x.description,
                id: x.reportId,
                created: x.created,
                createdBy: x.createdBy,
                description: x.comment,
              } as BaseDto)
          )}
          urlPrefix="/reports/"
          isSortVisible
        >
          <header>
            <div className="max-w-7xl">
              <h1 className="text-3xl font-bold leading-tight text-gray-900">
                Odpowiedzi
              </h1>
            </div>
          </header>{" "}
        </ItemsList>
      </div>
    </div>
  );
};
