import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { NavigationBadge, useNavigationBadgesStore } from "../..";
import {
  addQuestionDescription,
  getAllQuestions,
} from "../../api/questionsApi";
import { GenericItemsListPage } from "./genericItemsListPage";
import { BaseDto, QuestionDTO } from "../../types/Rows";
import { Button } from "@material-tailwind/react";
import AddItemWithExistingPicker, {
  InputType,
} from "../template/AddItemWithExistingPicker";
import { useTranslation } from "react-i18next";
import ItemsList from "../../components/lists/ItemsList";
import AddItemModal from "../template/addItemModal";
import { AddIcon } from "../studies/AddIcon";

export const QuestionsPage = () => {
  const { t } = useTranslation();
  const setItems = useNavigationBadgesStore((state: any) => state.setItems);
  const queryClient = useQueryClient();

  const { data, isFetching } = useQuery(["Questions"], getAllQuestions, {
    initialData: [],
  });

  const { mutate: addDescription } = useMutation(
    async (name: string) => {
      await addQuestionDescription({ value: name });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["Questions"]);
      },
    }
  );

  useEffect(() => {
    if (data) {
      setItems([{ text: "Pytania", url: "/Questions" } as NavigationBadge]);
    }
  }, [data, setItems]);

  return (
    <div className="mx-auto max-w-7xl sm:px-6 mt-3 lg:px-8">
      <div className="pb-8 sm:px-0">
        <ItemsList
          data={data.map((x) => {
            return {
              id: x.id,
              name: x.description,
              description: x.description,
              created: x.created,
              createdBy: x.createdBy,
            } as BaseDto;
          })}
          isLoading={isFetching}
          urlPrefix="questions/"
        >
          <header>
            <div className="max-w-7xl">
              <h1 className="text-3xl font-bold leading-tight text-gray-900 flex gap-1">
                {t("questions")}
                <AddItemModal
                  text={t("addQuestion")}
                  saveItem={(name) => addDescription(name)}
                >
                  <AddIcon />
                </AddItemModal>
              </h1>
            </div>
          </header>{" "}
        </ItemsList>
      </div>
    </div>
  );
};
